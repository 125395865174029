<template>
  <div 
    v-if="!isAnyFulfilled"
  >
      <b-dialog
        v-if="!isAnyFulfilled && !isAnyOrdered"
        mainTitle="YOU DID START ANY SUBSCRIPTION YET"
        subTitle="SO YOU DO NOT HAVE A COMPLETED LIST YET"
        :isSecondTitle=false
        secondTitle=""
        buttonIcon="mdi-cash-multiple"
        buttonText="START A SUBSCRIPTION"
        buttonDestination='Buy Package'
        :isSecondButton=true
        :isSecondButtonSwitch=true
        @dialogFeedback="switchDemo"

      />
      <b-dialog
        v-if="!isAnyFulfilled && isAnyOrdered"
        mainTitle="YOUR ORDER IS NOT YET COMPLETED"
        subTitle="YOUR LIST IS GETTING FILLED UP IN THE ONGOING ORDERS SECTION"
        :isSecondTitle=false
        secondTitle="OR YOU CAN SWITCH TO DEMO MODE"
        buttonIcon="mdi-purse"
        buttonText="GO TO ONGOING ORDERS"
        buttonDestination='Ongoing Orders'
        :isSecondButton=true
        :isSecondButtonSwitch=true
        @dialogFeedback="switchDemo"

      />
  </div>
  <v-container
    v-else
    id="completed"
    fluid
    tag="section"
    style="min-height: 75vh;"
  >
      <v-row>
        <v-col
          v-for="(list, listIndex) in fetchedOrLiveUser.lists"
          :key="listIndex"
          cols="12"
        >
          <delivery
            v-if="list.name_list.length == list.order_quantity"
            :list="list"
            @setEntryStatus="setEntryStatus"
            @addNote="addNote"
            @addFeedback="addFeedback"
          />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import delivery from '@/views/dashboard/component/Delivery.vue'
import BDialog from '@/views/dashboard/component/Dialog.vue'

export default {
  name: "Completed",
  components: {
    delivery,
    BDialog
  },
  props: ['fetchedOrLiveUser', 'liveFeed'],
  data() {
    return {

    };
  },

  computed: {
    isAnyFulfilled() {
      if (this.fetchedOrLiveUser.lists.length > 0) {
        this.fetchedOrLiveUser.lists.forEach( oneList => {
          if (oneList.name_list.length === oneList.order_quantity) {
            return false
          }
        })
      }
      return true
    },

    isAnyOrdered() {
      if (this.fetchedOrLiveUser.lists.length > 0) {
          return true
      }
      return false
    },

    reverseNetworks() {
      if (!this.networks) { return [] }
      const reversedArr = this.networks
      return reversedArr.reverse()
    },

    ...mapGetters(["networks", "networkColors"]),
  },

  created() {
    /*     this.handleGetUser(); */
  },

  methods: {
    switchDemo(event) {
      console.log('dialog answered: ', event)
    },
    setEntryStatus(newStatus) {
      this.$store.dispatch('setEntryStatus', newStatus)
    },
    addNote(data) {
      this.$store.dispatch('addNote', data)
    },
    addFeedback(data) {
      this.$store.dispatch('addFeedback', data)
    }
  },
};
</script>

<style scoped>
  .network {
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    align-items: center;
    -webkit-box-align: center;
    color: #FFFF;
    letter-spacing: 0 !important;
    line-height: 1rem;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif !important;
    white-space: nowrap;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
</style>
<style>
html {
  scroll-behavior: smooth;
}
</style>