<template>
    <v-container 
        id="dialog"
        fluid
    >
    <div
        class="back-image-container"
    >
        <img src="@/assets/dialog/1.svg" class="dialog-pic-pos dialog-pic-1">
        <img src="@/assets/dialog/2.svg" class="dialog-pic-pos dialog-pic-2">
        <img src="@/assets/dialog/3.svg" class="dialog-pic-pos dialog-pic-3">
        <img src="@/assets/dialog/4.svg" class="dialog-pic-pos dialog-pic-4">
        <img src="@/assets/dialog/5.svg" class="dialog-pic-pos dialog-pic-5">
        <img src="@/assets/dialog/6.svg" class="dialog-pic-pos dialog-pic-6">

        <div
            class="text-center mb-6 foreground"
        >
            <h1 class="dialog-text">{{mainTitle}}</h1>
            <h1 class="dialog-text-2">{{subTitle}}</h1>
        </div>


        <v-btn
            color="primary foreground"
            class="ma-2 white--text mb-6"
            @click="$router.push( { name: buttonDestination } )"
        >
            <v-icon
                class="mr-3" 
                dark>
                    {{ buttonIcon }}
            </v-icon>
                <span
                    class="button-text"
                >
                    {{ buttonText }}
                </span>
        </v-btn>

        <template
            v-if="isSecondTitle"
        >
        <div
            class="text-center mt-6 foreground"
        >
            <h1>{{ secondTitle }}</h1>
        </div>

        <v-switch
            v-if="isSecondButtonSwitch"
            :input-value="swithState"
            hide-details="true"
            class="demo-switch foreground"
            @change="changeMethod($event)"
        />

        <v-btn
            v-else
            color="primary foreground"
            class="ma-2 white--text mb-6"
            @click="$router.push( { name: secondButtonDestination } )"
        >
            <v-icon
                class="mr-3" 
                dark>
                    {{ secondButtonIcon }}
            </v-icon>
                <span
                    class="button-text"
                >
                    {{ secondButtonText }}
                </span>
        </v-btn>

        </template>

    </div>
  </v-container>
</template>

<script>
export default {
    props: ['mainTitle', 'subTitle', 'isSecondTitle', 'secondTitle', 'buttonIcon', 'buttonText', 'isSecondButton', 'isSecondButtonSwitch', 'secondButtonText', 'secondButtonIcon', 'buttonDestination', 'secondButtonDestination'],
    data() {
        return {
            swithState : false
        }
    },
    methods : {
        changeMethod(event) {
            this.$emit('dialogFeedback', event)
        }
    }
}
</script>

<style scoped>
  .foreground {
      z-index: 500;
  }
  .button-text {
      font-size : 1rem;
      font-weight: bold;
  }
  .dialog-text {
      font-size: 5rem;
  }
  .dialog-text-2 {
      font-size: 2rem;
      color: gray;
  }
  .demo-switch {
      transform: scale(1.5);
  }
  .back-image-container {
      width: 100%; 
      height: 87vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .dialog-pic-pos {
    position: absolute;
  }
  .dialog-pic-1 {
    top: 0;
    right: 70%;
  }
  .dialog-pic-2 {
    top: 10%;
    right: 50%;
  }
  .dialog-pic-3 {
    top: 0;
    left: 75%;
  }
  .dialog-pic-4 {
    top: 20%;
    right: 0;
  }
  .dialog-pic-5 {
    bottom: 0;
    right: 0;
  }
  .dialog-pic-6 {
    bottom: 0%;
    left: 0;
  }
</style>