<template>
    <base-material-card
      :color="isCompleted ? 'success' : 'info'"
      icon="mdi-check-bold"
      inline
      class="px-0 py-3"
      :class="{ 'progress-card' : !isCompleted}"
    >

      <template v-if="!isCompleted" v-slot:heading>
        <v-progress-circular
          :rotate="180"
          :size="100"
          :width="15"
          :value="orderProgressValue"
          color="pink"
        >
          <span class="progress-value">{{ orderProgressValue.toFixed(0) }}%</span>
          <v-icon
            class="in-row-icon"
            large
            v-if="Number(list.list_order) > 0"
          >
            mdi-clock-time-four
          </v-icon>
        </v-progress-circular>
      </template>

      <template v-slot:after-heading>
        <div class="delivery-info">
          <div class="font-weight-light">
            Product Name:
              <span 
                class="network"
                :style="{ backgroundColor: networkColors[list.network_id]}"
              >
                {{ networkName }}
              </span>
          </div>
          <div class="font-weight-light">
            <span>Order Time: {{ moment(list.order_date).fromNow() }}</span> 
          </div>
          <div class="m-0 p-0">
            <span style="font-weight: bold;" class="m-0 p-0">{{items.length}} / {{ list.order_quantity }} is completed</span> 
          </div>
        </div>
        <div class="m-0 p-0">
          <v-icon
            large
            class="opener-icon"
            v-if="!isOpened"
            @click="isOpened = !isOpened"
          >
            mdi-chevron-down
          </v-icon>
          <v-icon
            large
            class="opener-icon"
            v-else
            @click="isOpened = !isOpened"
          >
            mdi-chevron-up
          </v-icon>
        </div>
      </template>

      <template
        v-if="isOpened"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          label="Search"
          hide-details
          single-line
          style="max-width: 250px;"
        />

        <v-divider class="mt-3" />

        <v-row>
          <div class="delivery-message-container">
            <template
              v-if="payoffPieceNumber <= currentSuccessNumber"
            >
              <base-material-alert
                color="success"
                dark
                class="mr-2 ml-2"
                icon="mdi-check"
              >
                <h4>Congratulation! Your investment is returned.</h4>
                <h5>Your current profit is: {{currentProfit - networkCost}} eur</h5>
              </base-material-alert>
            </template>
            <template
              v-if="callToGo > 0 && currentProfit < networkCost && (((list.order_quantity - deadNumber) * networkPayoff) + currentProfit) >= networkCost"
            >
              <base-material-alert
                color="warning"
                dark
                class="mr-2 ml-2"
                icon="mdi-bell"
              >
                <h4>Earn your investment back!</h4>
                <h5>Call your next candidate!<span v-if="payoffPieceNumber - currentSuccessNumber === 1"> You are almost in profit!</span></h5>
                <h5>You have {{payoffPieceNumber - currentSuccessNumber}} candidate to go to win it back</h5>
              </base-material-alert>          
            </template>
            <template
              style="color: red;"
              v-if="callToGo === 0 && currentProfit < networkCost && (((list.order_quantity - deadNumber) * networkPayoff) + currentProfit) >= networkCost"
            >
              <base-material-alert
                color="warning"
                dark
                class="mr-2 ml-2"
                icon="mdi-bell"
              >
                <h4
                  v-if="Number(list.list_order) > 0"
                  >Status: waiting in row. We have {{list.list_order}} clients to serve before we start filling your list.
                </h4>
                <h4
                  v-else
                >You are not yet in profit, but wait until your next candidate comes in!</h4>
              </base-material-alert>   
            </template>
            <template
              v-if="currentProfit < networkCost && (((list.order_quantity - deadNumber) * networkPayoff) + currentProfit) < networkCost"
            >
              <base-material-alert
                color="pink darken-1"
                dark
                class="mr-2 ml-2"
                icon="mdi-bell"
              >
                <h4>Huhh seems like there won't be profit on this list!</h4>
                <h4>We are very sorry for this event. Please contact us so that we can further develop our service.</h4>
              </base-material-alert>             
            </template>       
          </div>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="items"
          :search.sync="search"
          :sort-by="['Acquired', 'name', 'city']"
          :sort-desc="[false, true]"
          :item-class="statusBackground"
          :items-per-page="Number(list.order_quantity)"

          multi-sort
        >
        <template v-slot:item="{ item }">
          <tr
            :style="{
              'border-right': '3px solid '+statusCodes[item.status.key].color,
              'border-left': '3px solid '+statusCodes[item.status.key].color
              }"
          >
            <td class="d-block d-sm-table-cell" :class="{ 'mobile' : isMobile }">
              <div class="content-wrapper">
                <div 
                  class="mobileProfileThumb"
                  v-if="isMobile"
                  :style="{
                    'background-image': 'url('+item.image+')'
                  }"
                >
                <v-icon
                  large
                  style="color: white;"
                >
                mdi-account
                </v-icon>
                </div>
                <span>{{ item.fullname }}</span>

                <v-icon
                    large
                    class="personal-data-opener-icon"
                    v-if="!item.opened && isMobile"
                    @click.stop="panelOpener(item.entryID)"
                  >
                    mdi-chevron-down
                </v-icon>
                <v-icon
                    large
                    class="personal-data-opener-icon"
                    v-if="item.opened && isMobile"
                    @click.stop="panelOpener(item.entryID)"
                  >
                    mdi-chevron-up
                </v-icon>

              </div>
            </td>
            <template
              v-if="item.opened || !isMobile"
            >
              <td class="d-block d-sm-table-cell" :class="{ 'mobile' : isMobile }"><div class="content-wrapper"><span>{{ item.city }}</span></div></td>
              <td class="d-block d-sm-table-cell" :class="{ 'mobile' : isMobile }"><div class="content-wrapper"><span>{{ item.acquireddate }}</span></div></td>
              <td class="d-block d-sm-table-cell" :class="{ 'mobile' : isMobile }" v-if="item.status.dbCode === 'TO_CALL_BACK'">
                <div class="mobile-callback-container">
                  <date-time-picker
                    label="Set call back time"
                    :datetime="item.recalltime"
                    @input="updateDatetime">
                  </date-time-picker>
                </div>
              </td>
              <td class="d-block d-sm-table-cell" v-if="!isMobile && item.status.dbCode !== 'TO_CALL_BACK'">
                <div style="width=100%; height=100%;">

                </div>
              </td>
              <td class="d-block d-sm-table-cell" :class="{ 'mobile' : isMobile }">
                <v-text-field
                  v-if="isMobile"
                  label="My notes"
                  class="purple-input"
                  v-model="item.mynotes"
                  @change="addNotes(item.entryID, item.mynotes)"
                />
                <v-text-field
                  v-else
                  class="purple-input"
                  v-model="item.mynotes"
                  @change="addNotes(item.entryID, item.mynotes)"
                />
              </td>
              <td class="d-block d-sm-table-cell" :class="{ 'mobile' : isMobile }">
                <v-text-field
                  v-if="isMobile"
                  label="Feedback to company"
                  class="purple-input"
                  v-model="item.feedback"
                  @change="addFeedback(item.entryID, item.feedback)"
                />
                <v-text-field
                  v-else
                  class="purple-input"
                  v-model="item.feedback"
                  @change="addFeedback(item.entryID, item.feedback)"
                />
              </td>
            </template>
          </tr>
          <!-- status slider -->
          <template
            v-if="item.opened || !isMobile"
          >
          <tr
            :style="{
              'border-right': '3px solid '+statusCodes[item.status.key].color,
              'border-left': '3px solid '+statusCodes[item.status.key].color
              }"
          >
            <td 
              colspan="8"
              class="d-block d-sm-table-cell bb-slider-container"
            >

              <v-slider
                @input="codeAndSetStatusCode($event, item)"
                ref="sliderRef"
                :value="item.status.key"
                step="1"
                max="4"
                ticks="always"
                tick-size="1"
                class="status-slider"
                :class="addIdClass(item)"
                hide-details
                :track-color="statusCodes[item.status.key].color"
                :track-fill-color="statusCodes[item.status.key].color"
                :thumb-color="statusCodes[item.status.key].color"
              >
              </v-slider>
              <div class="arrow-container">
                <v-icon class="shake-left">mdi-arrow-left-thick</v-icon>
                <v-icon class="shake-left">mdi-arrow-left-thick</v-icon>
                <div class="place-holder"></div>
                <v-icon class="shake-right">mdi-arrow-right-thick</v-icon>
                <v-icon class="shake-right">mdi-arrow-right-thick</v-icon>
              </div>
              <div
                v-if="item.status.key !== 0"
                class="phone-data-container"
              >
                <span
                  class="phone-data mt-3"
                >
                  {{ item.phone }}
                </span>
              </div>

              <div
                class="status-text-container"
              >
                <span
                  class="status-text mt-1"
                  :style="{'color': statusCodes[item.status.key].color}"
                  v-if="!item.updating"
                >
                  {{ item.status.message }}
                </span>
                <span
                  class="status-text"
                  :style="{'color': statusCodes[item.status.key].color}"
                  v-else
                >
                  <v-progress-circular
                    indeterminate
                    size="10"
                    width="2"
                    class="status-spinner"
                  ></v-progress-circular>
                </span>
              </div>
            </td>
          </tr>
          <tr
            class="row-spacer">

          </tr>
          <hr
            style="width: 95%; position: absolute;"
            :class="{ 'mobile-line' : isMobile}"
          >
          <tr
            class="row-spacer">

          </tr>
        </template>
        </template>
        </v-data-table>
      </template>
    </base-material-card>
</template>

<script>
  import { mapGetters } from "vuex"
  import DateTimePicker from "@/views/dashboard/component/DateTimePicker.vue"
  /* import store from './store' */

  export default {
    name: 'DashboardDataTables',
    components: {
      DateTimePicker
    },
    props: ['list'],
    data: () => ({
      isPersonalOpened: false,
      isOpened: false,
      isMobile: false,
      statusUpdate: false,
      statusCodes: 
      [
        {
          key: 0,
          dbCode: 'RIP',
          message: 'client lost',
          color: 'black'
        },
        {
          key: 1,
          dbCode: 'NOT_RESPONSIVE',
          message: 'never picked up',
          color: 'blue'
        },
        {
          key: 2,
          dbCode: 'TO_CALL',
          message: 'to call',
          color: 'red'
        },
        {
          key: 3,
          dbCode: 'TO_CALL_BACK',
          message: 'call again another time',
          color: 'orange'
        },
        {
          key: 4,
          dbCode: 'SUCCESS',
          message: 'BUSINESS SUCCESSFUL',
          color: 'green'
        }      
      ],
      datetime:'2017-06-30 11:05:00',
      label:'Custom Component',
      dateD: '2021-05-01',
      timePickerOpen: false,
      notesInput: '',
      simulationInterval: {},
      headers: [
        {
          text: 'Full name',
          value: 'fullname',
        },
        {
          text: 'City',
          value: 'city',
        },
        {
          text: 'Acquired',
          value: 'acquireddate',
        },
        {
          text: '(Re)call time',
          value: 'recalltime',
        },
        {
          text: 'My notes',
          value: 'mynotes',
        },
        {
          text: 'Feedback',
          value: 'feedback',
        },
      ],
      search: undefined,
    }),
    beforeDestroy () {
      clearInterval(this.simulationInterval)
      if (typeof window === 'undefined') {
        return
      }
      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })

/*       this.simulationInterval = setInterval(() => {
        if (this.orderProgressValue === 100) {
          return (this.orderProgressValue = 0)
        }
        this.orderProgressValue += 10
      }, 1000) */

    },
    computed: {
      ...mapGetters(["currentUser", "networks", "networkColors"]),
      items() {
        const items = []
        for (const item of this.list.name_list) {
          const itemToPush = {
            fullname: item.client_name,
            phone: item.client_phone,
            city: item.client_city,
            email: item.client_email,
            image: item.client_pic,
            status: {
              dbCode: item.status,
              message: this.decodeStatusMessage(item.status),
              key: this.decodeStatusCode(item.status)
            },
            acquireddate: this.moment(item.acquire_datetime).format('YYYY-MM-DD hh:mm'),
            recalltime: this.moment(item.recall_time).format('YYYY-MM-DD hh:mm'),
            /* mynotes: item.own_notes, */
            mynotes: item.own_notes,
            feedback: item.feedback,
            entryID: item._id,
            updating: false,
            opened: item.is_panel_open
          }
        // eslint-disable-next-line
        itemToPush['userID'] = this.list.userID._id
        // eslint-disable-next-line
        itemToPush['listID'] = this.list._id
        items.push(itemToPush)
        }
        return items
      },
      product() {
        return {
          name: 'Quantum',
          orderedAt: '2021-10-05'
        }
      },
      isCompleted() {
        return (this.list.order_quantity < this.list.name_list.length)
      },
      networkName() {
        const thisNetwork = this.networks.filter(searchName => {
          return searchName.net_id === this.list.network_id
        })
        return thisNetwork[0].name
      },

      networkPayoff() {
        const networkPayoff = this.networks.filter(searchName => {
          return searchName.net_id === this.list.network_id
        })
        return Number(networkPayoff[0].payoff)
      },

      currentProfit() {
        if (!this.items) {
          return null
        }

        const success = this.items.filter(searchProfit => {
          return searchProfit.status.dbCode === 'SUCCESS'
        })

        return (success.length * this.networkPayoff)
      },

      networkCost() {
        const networkCost = this.networks.filter(searchName => {
          return searchName.net_id === this.list.network_id
        })
        return Number(networkCost[0].price)
      },

      payoffPieceNumber() {
        return Math.ceil(this.networkCost / this.networkPayoff)
      },

      currentSuccessNumber() {
        const success = this.items.filter(searchProfit => {
          return searchProfit.status.dbCode === 'SUCCESS'
        })

        return success.length
      },

      callToGo() {
        const toCall = this.items.filter(searchEntry => {
          return (searchEntry.status.dbCode === 'TO_CALL' || searchEntry.status.dbCode === 'TO_CALL_BACK')
        })
        return toCall.length
      },

      deadNumber() {
        const dead = this.items.filter(searchEntry => {
          return (searchEntry.status.dbCode === 'NOT_RESPONSIVE' || searchEntry.status.dbCode === 'RIP')
        })
        return dead.length
      },

      orderProgressValue() {
        if (this.list) {
          return (this.list.name_list.length / this.list.order_quantity) * 100
        }
        return 0
      }
    },
    watch: {

    },
    methods: {
      panelOpener(entryID) {
        const entryIdOnj = {
          entryID : entryID
        }
        this.$store.dispatch('setPanelState', entryIdOnj)
      },
      addNotes(entryID, note) {
        console.log('addNotes: ', entryID, " - ", note);
        this.$emit('addNote', {entryID, note})
      },
      addFeedback(entryID, feedback) {
        this.$emit('addFeedback', {entryID, feedback})
      },
      addIdClass(item) {
        return 'canID-'+item.entryID+'-'+item.image;
      },
      onResize () {
        this.isMobile = window.innerWidth < 600
      },
      statusBackground(item) {
        if (item.status === 'TO_CALL') {
          return 'tocall-backgound'
        }
        if (item.status === 'SUCCESS') {
          return 'success-backgound'
        }
        if (item.status === 'TO_CALL_BACK') {
          return 'to-call-back-backgound'
        }
        if (item.status === 'RIP') {
          return 'rip-backgound'
        }    
        return null
      },

      setEntryUpdatingStatus(entryID, state) {
        if (!this.items) {
          return null
        }
        const entryIndex = this.items.findIndex(x => x.entryID === entryID);
        this.items[entryIndex].updating = state
      },

      codeAndSetStatusCode(e, dbItem) {
        this.setEntryUpdatingStatus(dbItem.entryID, true)
        this.statusUpdate = true;
        const newDbCode = this.decodeStatusMessageFromCode(e)
        const updateStatus = {
          entryID: dbItem.entryID,
          newStatus: newDbCode
        }

        this.$emit('setEntryStatus', updateStatus)
      },

      decodeStatusCode(statusData) {
        const findIndex = this.statusCodes.filter(obj => {
          return obj.dbCode === statusData
        })
        return findIndex[0].key
      },

      decodeStatusMessage(statusData) {
        const findIndex = this.statusCodes.filter(obj => {
          return obj.dbCode === statusData
        })
        return findIndex[0].message
      },

      decodeStatusMessageFromCode(statusData) {
        const findIndex = this.statusCodes.filter(obj => {
          return obj.key === statusData
        })
        return findIndex[0].dbCode
      },
      updateDatetime(datetime){
        this.datetime = datetime;
      },
      updateUI() {
        this.$nextTick(() => {
            const sliders = document.querySelectorAll('.status-slider')
            if (sliders.length === 0) {
              return null;
            }
            sliders.forEach(function (item, index) {

                const classList = [...item.classList];
                const findClass = classList.filter(str => {
                    return str.includes('canID');
                });

                if (!findClass) {
                  return null;
                }
                
                const dataFromClass = findClass[0].split("-");
                const targetThumb = item.firstChild.firstChild.firstChild.childNodes[3].firstChild;
                const newThumbID = index+'-targetthumb';

                targetThumb.id = newThumbID;
                document.getElementById(newThumbID).style.backgroundImage="url('"+dataFromClass[2]+"')";
                document.getElementById(newThumbID).style.backgroundSize="cover";
            });
        });
      }
    },
    created() {
      this.updateUI();
    },
    updated() {
      this.updateUI();
    },
  }
</script>

<style scoped>
.in-row-icon {
    position: absolute;
    top: -22px;
    right: -22px;
    color: #e91e63;
}
.network {
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    font-size: 80%;
    font-weight: 500;
    align-items: center;
    -webkit-box-align: center;
    color: #FFFF;
    letter-spacing: 0 !important;
    line-height: 1rem;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif !important;
    white-space: nowrap;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
}
.v-progress-circular {
  margin: 1rem;
}
.progress-value {
  font-weight: 900;
  font-size: 14px;
}
.v-progress-circular {
  height: 60px!important;
  width: 60px!important;
}
.delivery-info {
  position: relative;
  top: -10px;
}
.status-slider {
  height: 2em;
  padding-right: 30px;
  z-index: 500;
  position: relative;
  margin-bottom: -32px;
}

.status-text-container {
  width: 100%;
  min-width: 86px;
  display: flex;
  justify-content: center;
  min-height: 37px;
}
.phone-data-container {
  width: 100%;
  min-width: 86px;
  display: flex;
  justify-content: center;
}
.arrow-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 32px;
  position: relative;
  background-color: transparent;
  top: 1px;
  z-index: 100;
}
.phone-data {
  font-weight: 900;
  font-size: 20px;
  font-style: italic;
  letter-spacing: 0.1rem;
  text-decoration: underline;
  color: gray;
}
.status-text {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.status-spinner {
  height: 20px!important;
  width: 20px!important;
  margin: 0!important;
  padding: 0!important;
}

.tocall {
  background-color: #fdc4bf;
}
.tocall :hover {
  background-color:#fdc4bf;
}
.success {
  background-color: #97ff9b;
}
.callback {
  background-color: #ffd79c;
}
.rip {
  background-color: #c0c0c0;
}
.bb-slider-container{
  padding-right: 20px!important;
  padding-left: 20px!important;
  min-height: 94px;
  height: initial!important;
  position: relative;
  bottom: -15px;
  right: 5px;
}
.place-holder {
  width: 1px;
  height: 1px;
  background: transparent;
}
/* .mobileProfileThumb {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 2px solid gray;
  background-size: cover;
  margin-right: 20px;
} */
.mobileProfileThumb {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background-color: #e91e63;
  margin-right: 15px;
}
.delivery-message-container {
  width: 100%;
  text-align: center;
}
.opener-icon {
  color: black;
  position: absolute;
  right: 10px;
  top: 9px;
}

.personal-data-opener-icon {
  position: absolute;
  right: 0px;
}

@media screen and (max-width: 500px) {
  .delivery-info {
    font-size: 0.8em;
  }
  h4 {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 400px) {
  .delivery-info {
    font-size: 0.8em;
  }
  h4 {
    font-size: 0.9em;
  }
}
</style>
<style>
@media screen and (min-width: 500.1px) {
  .v-chip__content {
    font-size: 0.8em!important;
  }
}
@media screen and (max-width: 500px) {
  .v-label {
    font-size: 0.8em!important;
  }
  .v-alert {
    padding: 10px 15px!important;
  }
  .v-chip__content {
    font-size: 0.6em!important;
  }
}

@media screen and (max-width: 400px) {
  .v-label {
    font-size: 0.6em!important;
  }
  .v-alert {
    padding: 7px 15px!important;
  }
  .v-chip__content {
    font-size: 0.5em!important;
  }
}

table {
  overflow: hidden;
}
.delivery-message-container > .v-alert > .v-alert__wrapper > .v-alert__content {
  font-size: 120%!important;
}
.delivery-message-container > .v-alert > .v-alert__wrapper > .v-alert__content > h5 > span {
  font-size: 100%!important;
  font-weight: bold;
}

.progress-card .v-card--material__heading {
  max-height: 100%!important;
  padding: 0!important;
}

.v-slider__thumb:before {
  width: 40px!important;
  height: 40px!important;
  left: 5px;
  top: 5px;

}
.v-slider__thumb {
  width: 50px!important;
  height: 50px!important;
  left: -25px!important;
}
.v-slider--horizontal {
      margin-right: -20px!important;
}
.arrow-container > i {
  transform: scale(2)!important;
  position: relative;
  z-index: 700;
  color: #b5b5b5!important;
}
.v-data-table__wrapper {
  padding-left: 1px!important;
}
table {
  border-collapse: collapse;
  width: calc(100% - 6px)!important;
}
.d-sm-table-cell {
  height: 50px!important;
}
.mobile > .content-wrapper {
  width : 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  position: relative;
}
.mobile > .mobile-callback-container > .v-text-field > .v-input__control > .v-input__slot {
  width: initial;
  margin-right: auto;
  margin-left: auto;
  font-size:20px;
}
.mobile > .v-text-field > .v-input__control > .v-input__slot:before {
  bottom: 0px!important;
}
.mobile > .v-text-field > .v-input__control > .v-input__slot:after {
  bottom: 0px!important;
}
.mobile > .purple-input > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  position: relative;
  bottom: -6px;
}
.mobile > .mobile-callback-container > .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot > label {
  right: initial!important;
  left: 50%!important;
  top: -15px!important;
  transform: translateX(-50%)!important;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.mobile > .mobile-callback-container > .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  width: initial;
  flex: initial;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.mobile > .purple-input > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  text-align: center;
  font-size: 16px;
}
.mobile > .purple-input > .v-input__control > .v-input__slot > .v-text-field__slot > label {
  right: initial!important;
  left: 50%!important;
  top: -10px!important;
  transform: translateX(-50%)!important;
  font-size: 18px;
  font-weight: bold;
  color: black;
}
.row-spacer {
  height: 30px!important;
}
.mobile-line {
  width: 90%!important;
}
.v-data-footer {
  display: none!important;
}
td {
  transition: all 1s!important;
}
tr {
  transition: all 1s!important;
}
.v-alert .v-alert__wrapper .v-alert__content {
  position: relative!important;
  right: 28px;
}
.shake-right {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: shake-right;
}
.shake-left {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: shake-left;
}
@keyframes shake-right {
  0%   {left: 0px;}
  50%  {left: 10px;}
  100% {left: 0px}
}
@keyframes shake-left {
  0%   {left: 10px;}
  50%  {left: 0px;}
  100% {left: 10px}
}
</style>
